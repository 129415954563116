import React, { useState, useMemo } from 'react';
import { Box, Autocomplete, TextField, Typography, Paper, InputAdornment, styled, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from "react-router-dom";
import { ROUTES } from '../../app/routes';
import { useAppSelector } from '../../app/hooks';
import { AuthState } from '../../app/slices/authSlice';
import useFetchSearch from '../../app/hooks/useFetchSearch';
import { ISearchResult } from '../../app/types/search';
import { useAuthDialog } from '../../context/auth-dialog-context';


interface SearchResult {
    label: string;
    type: 'User' | 'Stock' | 'Crypto';
    item: ISearchResult;
}

const forEach = <T,>(list: T[] | undefined = [], callback: (item: T) => void, maxLength: number = 3) => {
    for (let i = 0; i < maxLength; i++) {
        if (list[i]) {
            callback(list[i])
        }
    }
}

const ListPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginTop: theme.spacing(1),
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(1),

    '& .MuiAutocomplete-listbox': {
        maxHeight: 'unset',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.75),
        '& .list-group': {
            paddingBottom: theme.spacing(1),
            fontWeight: '500', fontSize: theme.spacing(1.75), lineHeight: 1.5, color: '#1E1F24'
        },
        '& li': {
            fontWeight: '500', fontSize: theme.spacing(1.75), lineHeight: 1.5, color: '#757575',
            padding: theme.spacing(.5, 1, .5, 1),
            '&.Mui-focused': {
                background: '#D6EEFF',
                width: 'max-content',
                color: '#1E1F24',
                borderRadius: theme.spacing(.5),
            }

        }
    }
}));

const groupLabels: Record<SearchResult['type'], string> = {
    'User': 'Users',
    'Stock': 'Stocks',
    'Crypto': 'Crypto'
}

const Search: React.FC = () => {
    const [query, setQuery] = useState<string>('');
    const { userData } = useAppSelector((state) => state.auth) as AuthState;
    const { handleOpenSignIn } = useAuthDialog()
    const { data, isLoading } = useFetchSearch(query)

    const results = useMemo(() => {
        let list: SearchResult[] = [];
        forEach(data?.user || [], (item) => list.push({ type: 'User', label: `${item.name} (${item.pathId})`, item }));
        forEach(data?.stocks || [], (item) => list.push({ type: 'Stock', label: `${item.label} (${item.name})`, item }));
        forEach(data?.crypto || [], (item) => list.push({ type: 'Crypto', label: `${item.label} (${item.name})`, item }));
        return list;
    }, [data])

    const navigate = useNavigate();


    const handleSearchChange = (_event: React.ChangeEvent<{}>, value: string | null): void => {
        setQuery(value ?? ''); // Update query state
    };

    const handleNavigate = (id: string, type: 'user' | 'ticker' = 'ticker') => {
        navigate(type === 'ticker' ? ROUTES.tickers.byId(id) : ROUTES.profile.byUsername(id));
    };

    const onChange = (_event: React.SyntheticEvent, value: SearchResult | string | null) => {
        if (!userData) {
            handleOpenSignIn()
            return;
        }
        if (!value) return
        const { item } = value as SearchResult;
        handleNavigate((item as ISearchResult).pathId, item.type === 'user' ? 'user' : 'ticker');
    }
    return (
        <Box
            sx={{
                fontFamily: 'Roboto',
                position: 'relative',
            }}
        >
            <Box display="flex" alignItems="center" justifyContent={{ xs: 'center', md: 'flex-start' }}>

                <Autocomplete
                    freeSolo
                    options={results} // Use dynamic results from backend
                    inputMode="search"
                    color="white"
                    loading={isLoading}
                    groupBy={(option) => option.type}
                    getOptionLabel={(option: any) => option.label}
                    onChange={onChange}
                    onInputChange={handleSearchChange}
                    disableListWrap
                    PaperComponent={ListPaper}
                    renderGroup={(params) => (
                        <Stack gap={.75} key={params.key}>
                            <Typography className='list-group'>
                                {groupLabels[params.group as SearchResult['type']]}
                            </Typography>
                            {params.children}
                        </Stack>
                    )}
                    clearIcon={
                        <ClearIcon sx={{ color: '#CDCED7' }} />
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            value={query}
                            placeholder="Search"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="medium" htmlColor="#CDCED7" />
                                    </InputAdornment>
                                ),
                                sx: {
                                    marginTop: { xs: '1rem', md: 0 },
                                    backgroundColor: 'transparent',
                                    borderRadius: '0.5rem',
                                    height: "3rem",
                                    border: '2px solid #CDCED7',
                                    width: '20rem',
                                    fontFamily: 'Roboto',
                                    marginLeft: { xs: 0, md: '4rem' },
                                    textAlign: { xs: 'center', md: 'left' },
                                    color: 'inherit',
                                    padding: '0.4rem 0rem',
                                    fontSize: '1rem',
                                    input: {
                                        '::placeholder': {
                                            color: 'white',
                                            fontFamily: 'Roboto',
                                            opacity: '100%',
                                            fontSize: '0.9rem',
                                        },
                                    },
                                },
                            }}
                        />
                    )}
                />
            </Box>
        </Box>
    );
};

export default Search;
