import React from "react";
import Box from "@mui/material/Box";
import { IconButton, Modal } from "@mui/material";
import SignUp from "../SignUp/SignUp";
import SignIn from "../SignIn/SingIn";
import CloseIcon from "@mui/icons-material/Close";
import { useAuthDialog } from "../../context/auth-dialog-context";

const AuthDialog: React.FC = () => {
  const {
    signUpOpen,
    signInOpen,
    handleOpenSignUp,
    handleOpenSignIn,
    handleClose,
  } = useAuthDialog();

  return (
    <>
      <Modal
        sx={{
          overflowY: "auto",
        }}
        open={signUpOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* sign up component here */}
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "28.5rem",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pb: 4,
            pt: 2,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <IconButton
              sx={{ fontSize: "1.5rem", justifySelf: "end" }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <SignUp
            handleOpenSignIn={handleOpenSignIn}
            handleClose={handleClose}
          />
        </Box>
      </Modal>

      {/* Sign in modal */}
      <Modal
        sx={{
          overflowY: "auto",
        }}
        open={signInOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* sign In component here */}
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "28.5rem",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pb: 4,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "end", top: 0, mt: 0 }}>
            <IconButton
              sx={{ fontSize: "1.5rem", justifySelf: "end" }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <SignIn
            handleOpenSignUp={handleOpenSignUp}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
};

export default AuthDialog;
