import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import logoSvg from '../../assets/icons/MemeStock.svg';
import { IconButton, Grid, SxProps, Theme } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { AuthState } from '../../app/slices/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../app/routes';
import Search from '../Search/Search';
import { useAuthDialog } from '../../context/auth-dialog-context';

const Header = ({ sx }: { sx?: SxProps<Theme> }) => {
  const { userData, memeStockToken } = useAppSelector((state) => state.auth) as AuthState;
  const { handleOpenSignIn, handleOpenSignUp } = useAuthDialog()
  const navigate = useNavigate()

  const redirectToProfile = () => {
    navigate(ROUTES.profile.index);
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: 'primary', pt: '0.9rem', height: "26.5rem", ...sx }}>
      <Container maxWidth={false} sx={{ mx: 0 }}  >
        <Toolbar disableGutters>
          <Grid container alignItems="center" spacing={2}>
            {/* logo */}
            <Grid item xs={6} md="auto">
              <Link to={ROUTES.index}>
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'flex-start', md: 'flex-start' },
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  <img src={logoSvg} alt="MemeStock" style={{ maxWidth: '100%', height: 'auto' }} />
                </Typography>
              </Link>
            </Grid>

            {/* this icon displayed in mobile view to show submenu ... it can be changed later  */}
            {/* menu icon */}
            <Grid item xs={6} md="auto" sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
              <IconButton color="inherit">
                <MenuIcon />
              </IconButton>
            </Grid>
            {/* search input */}
            <Grid item xs={12} md>
              <Search />
            </Grid>
            {/* Buttons */}

            <Grid item xs={12} md="auto" sx={{ display: { xs: 'none', md: 'flex' }, mr: 0, justifySelf: 'end' }}>
              {!memeStockToken?.length && !localStorage.getItem("memeStockToken") ?
                (<Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, alignItems: 'center' }}>
                  <Button color="inherit" onClick={handleOpenSignIn} className="button-primary"
                    sx={{
                      marginRight: '0.625rem',
                      fontFamily: 'Roboto',
                      fontWeight: 700,
                      border: "1px solid #ffffff",
                      borderRadius: "0.5rem",
                      padding: "0.7rem 1rem"

                    }}
                  >
                    Sign In
                  </Button>
                  <Button variant="contained" onClick={handleOpenSignUp}
                    className="button-secondary"
                    sx={{
                      color: "#1e1f24",
                      border: "1px solid transparent",
                      borderRadius: "0.5rem",
                      padding: "0.7rem 1rem",
                      fontWeight: "700",
                      boxShadow: "0px 2px 4px -2px rgba(33, 33, 33, 0.2)"

                    }}
                  >
                    Get Started
                  </Button>
                </Box>)
                :
                (
                  <Box
                    role="navigation"
                    onClick={redirectToProfile}
                    sx={{ display: 'flex', cursor: "pointer", flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', height: '100%' }}>
                    <Typography variant='h1' sx={{ fontFamily: 'Roboto', fontSize: '1rem', fontWeight: 600, ml: 0, mb: 1 }}>
                      {userData?.name}
                    </Typography>
                    <Typography variant='h1' sx={{ fontFamily: 'Roboto', fontSize: '0.85rem', fontWeight: 400, ml: 0 }}>
                      @{userData?.username}
                    </Typography>
                  </Box>
                )}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar >
  );
}

export default Header;