import {
  Box,
  Container,
  Link,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import React from "react";
import { docPagesLinks } from "../constants/links";

const styles: Record<string, SxProps<Theme>> = {
  borderBottom: { borderBottom: "3px solid #EEEEEE" },
  container: { mx: 0 },
  footerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    pt: "6rem",
    pb: "4rem",
  },
  logoTypography: {
    fontFamily: "Nexa",
    color: "#1E1F24",
    fontWeight: 700,
    fontSize: "2.5rem",
    lineHeight: "2.9rem",
  },
  link: {
    fontFamily: "Roboto",
    color: "#9E9E9E",
    fontWeight: 500,
    fontSize: "0.9rem",
    "&:hover": {
      textDecoration: "underline", // Add hover effect
      cursor: "pointer", // Change cursor to pointer
    },
  },
  linksBox: {
    display: "flex",
    justifyContent: "flex-end", // Aligns links to the right
    gap: "1.8rem", // Adds space between links
    marginBottom: 3,
  },
  disclaimerText: {
    fontFamily: "Roboto",
    color: "#9E9E9E",
    fontSize: "0.9rem",
  },
};

// Define the component as a functional component with no props
const Footer: React.FC = () => {
  return (
    <Box>
      <Box sx={styles.borderBottom} />
      <Container maxWidth={false} sx={styles.container}>
        <Box sx={styles.footerBox}>
          <Box>
            <Typography sx={styles.logoTypography}>MemeStock</Typography>
          </Box>
          <Box sx={{ maxWidth: "622px" }}>
            <Box sx={{ ...styles.linksBox }}>
              {docPagesLinks.slice(0, -2).map((item) => (
                <Link
                  key={item.link}
                  href={item.link}
                  underline="none"
                  sx={styles.link}
                >
                  {item.title.split(" ")[0]}
                </Link>
              ))}
            </Box>
            <Typography sx={styles.disclaimerText}>Disclaimer:</Typography>
            <Typography sx={styles.disclaimerText}>
              The tools and information provided by MemeStock LLC are for
              informational purposes only and not financial advice. Using the
              platform to predict stock prices involves risks, including market
              volatility, financial loss, and unforeseen events. We do not
              guarantee the accuracy or success of predictions. Users are solely
              responsible for their decisions, and we are not liable for any
              losses or outcomes. Consult a licensed financial advisor for
              professional guidance. By using the platform, you accept these
              risks.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
