import React, { ReactNode } from 'react';
import { Container, ContainerProps } from '@mui/material'; // Assuming you're using Material-UI's Container

interface CustomContainerProps extends ContainerProps {
    children: ReactNode;
}

const CustomContainer: React.FC<CustomContainerProps> = ({ children, sx, ...rest }) => {
    return (
        <Container maxWidth={false} sx={{ position: 'relative', marginTop: '-18rem', marginBottom: '20rem', ...sx }} {...rest}>
            {children}
        </Container>
    );
};

export default CustomContainer;