import { useQuery } from '@tanstack/react-query';
import { SearchData } from '../types/search';
import axiosInstance from '../api/axiosInstance';


const useFetchSearch = (search: string) => {
  return useQuery<SearchData>({
    queryKey: ['search', search],
    queryFn: () => axiosInstance.get(`/api-auth/search?searchTerm=${search}`).then(res => res.data),
    retry: false,
    enabled: !!search
  });
};

export default useFetchSearch;