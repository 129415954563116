import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IToast {
    id: string;
    message: string;
    severity: 'success' | 'error' | 'warning' | 'info';
    duration?: number; // duration in ms
}

interface IToastState {
    toasts: IToast[];
}

const initialState: IToastState = {
    toasts: [],
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        addToast(state, action: PayloadAction<Omit<IToast, 'id'>>) {
            const id = new Date().toISOString();
            state.toasts.push({ id, ...action.payload });
        },
        removeToast(state, action: PayloadAction<string>) {
            state.toasts = state.toasts.filter(toast => toast.id !== action.payload);
        },
    },
});

export const { addToast, removeToast } = toastSlice.actions;
export default toastSlice.reducer;