import { Box, Grid, Typography } from '@mui/material'

interface TextCardProps {
    title: string,
    paras: string[]
}
const TextCard = ({ title, paras }: TextCardProps) => {
    return (
        <Box sx={{ placeSelf: 'center', placeItems: 'center', backgroundColor: 'white', maxWidth: '794px', width: '100%', borderRadius: '20px', paddingTop: "54px", paddingBottom: "54px", paddingLeft: '103px', paddingRight: "103px" }}>
            <Typography variant='h1' sx={{ fontFamily: "Nexa", color: "#20184E", fontWeight: 'bold', fontSize: "2.5rem", lineHeight: "2.9rem" }}>
                {title}
            </Typography>
            <Grid xs={9} style={{ marginTop: '1rem', placeItems: 'center' }}>
                {paras.map(item => <Typography key={item} sx={{ fontFamily: "Roboto", color: "#666666", fontSize: "1.125rem", lineHeight: "2rem" }}>
                    {item}
                </Typography>)}
            </Grid>
        </Box>
    )
}

export default TextCard