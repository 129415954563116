export default function handleShowPasswordError(passwordErrorMessage: string = "") {
    const msgAfterSplit = passwordErrorMessage?.split("-")
    // if password message contains "-" then split will be greater than 1 then format the message
    // otherwise show the original message
    if (msgAfterSplit?.length > 1) {
        return msgAfterSplit.map((str: string, index: number) => (
            index !== 0 && (
                <span key={index}>
                    {str.length > 1 ? "-" + str : ""}
                    < br />
                </span>)
        ))
    } else {
        return (
            <>
                {passwordErrorMessage}
            </>
        )

    }


}