import React from "react";
import {
  Stack,
  Typography,
  Link,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  TableContainer,
  Table,
} from "@mui/material";
import { FontsFamily } from "../constants/fonts-enum";

interface ParserProps {
  data: any;
}

export const RichTextParser: React.FC<ParserProps> = ({ data }) => {
  const renderFormattedText = (text: string) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const linkRegex = /\[(.*?)\]\((.*?)\)/g;
    const singleQuoteRegex = /'([^']+)'/g;
    const lineBreakRegex = /\n/g;
    const h3Regex = /\*\*\*(.*?)\*\*\*/g;

    let formattedText = text.replace(singleQuoteRegex, '**"$1"**');

    formattedText = formattedText.replace(lineBreakRegex, " ");

    const parts = formattedText.split(
      /(\*\*\*.*?\*\*\*|\*\*.*?\*\*|\[.*?\]\(.*?\))/g
    );

    return parts.map((part, index) => {
      if (h3Regex.test(part)) {
        return (
          <Typography
            key={index}
            variant="h3"
            sx={{
              color: "#333",
              fontWeight: "bold",
              fontSize: "20px",
              marginTop: 2,
            }}
            id={part
              .toLowerCase()
              .replace(/\*\*\*/g, "") // Remove `***`
              .replace(/\s+/g, "-") // Replace spaces with `-`
              .replace("/.$/g", "")}
          >
            {part.replace(/\*\*\*/g, "")}
          </Typography>
        );
      }

      if (boldRegex.test(part)) {
        return (
          <Typography
            key={index}
            component="span"
            sx={{ fontWeight: "bold", color: "#333" }}
          >
            {part.replace(/\*\*/g, "")}
          </Typography>
        );
      }

      if (linkRegex.test(part)) {
        const [, linkText, url] = part.match(/\[(.*?)\]\((.*?)\)/) || [];
        return (
          <Link
            key={index}
            href={url}
            target={
              url.includes("#") && !url.includes("http") ? "_self" : "_blank"
            }
            rel="noopener noreferrer"
            sx={{ color: "#1e88e5", textDecoration: "underline" }}
          >
            {linkText}
          </Link>
        );
      }

      return part;
    });
  };

  const renderList = (items: string[], type: "ul" | "ol") => {
    const ListTag = type === "ul" ? "ul" : "ol";

    return (
      <ListTag
        style={{
          paddingLeft: "20px",
          listStyleType: type === "ul" ? "disc" : "decimal",
          marginTop: 0,
        }}
      >
        {items.map((item, index) => (
          <li key={index}>
            <Typography
              sx={{
                color: "#666666",
                fontSize: "18px",
                lineHeight: "160%",
              }}
            >
              {renderFormattedText(item)}
            </Typography>
          </li>
        ))}
      </ListTag>
    );
  };
  const renderTable = (table: { head: string[]; body: string[][] }) => {
    return (
      <TableContainer
        style={{ margin: "3rem 0" }}
        component={Paper}
        sx={{ marginY: 6, fontSize: 18 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {table.head.map((col, index) => (
                <TableCell
                  key={index}
                  sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {table.body.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const renderContent = (
    content: string | (string | string[])[] | any,
    index?: number
  ) => {
    if (Array.isArray(content)) {
      if (
        content.every(
          (item) =>
            typeof item === "string" &&
            (item.startsWith("-") || item.startsWith("_"))
        )
      ) {
        const listType = (content as string[])?.[0]?.startsWith("-")
          ? "ul"
          : "ol";
        return renderList(
          (content as string[]).map((item) => item.slice(2)),
          listType
        );
      }

      return content.map((item, index) => {
        if (item?.table) {
          return renderTable(item?.table);
        }
        if (typeof item === "string") {
          return (
            <Typography
              key={index}
              sx={{
                color: "#666666",
                fontSize: "18px",
                lineHeight: "160%",
                marginBottom: 2,
              }}
            >
              {renderFormattedText(item)}
            </Typography>
          );
        }

        if (Array.isArray(item)) {
          return <Stack key={index}>{renderContent(item)}</Stack>;
        }

        return null;
      });
    }

    return (
      <Typography
        sx={{ color: "#666666", fontSize: "18px", lineHeight: "160%" }}
      >
        {renderFormattedText(content)}
      </Typography>
    );
  };

  const renderSection = (section: any, index: number) => {
    const id = section.title.toLowerCase().replace(/\s+/g, "-"); // Creates anchor links

    return (
      <Stack key={index} spacing={2} sx={{ marginTop: 1 }}>
        <Typography
          variant="h3"
          id={id}
          sx={{ color: "#333", fontWeight: "bold", fontSize: "20px" }}
        >
          {data.headingsWithNumbers ? index + 1 + ". " : ""}
          {section.title}
        </Typography>
        {section.content && renderContent(section.content, index)}
        {section.subsections &&
          section.subsections.map((sub: any, idx: number) => (
            <Stack key={idx} sx={{ marginLeft: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{ color: "#444", fontWeight: "bold", fontSize: "18px" }}
              >
                {sub.subtitle}
              </Typography>
              {renderContent(sub.content)}
            </Stack>
          ))}
      </Stack>
    );
  };

  const renderTOC = () => (
    <Stack spacing={2} sx={{ marginBottom: 4 }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          fontSize: "20px",
          color: "#333",
          marginBottom: 2,
        }}
        id="table-of-content"
      >
        Table of Contents
      </Typography>
      <ul
        style={{
          paddingLeft: "20px",
          listStyleType: "none",
          marginTop: 20,
          gap: 12,
          fontFamily: FontsFamily.Roboto,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {data.sections.map((section: any, index: number) => {
          const link = section.title.toLowerCase().replace(/\s+/g, "-"); // Creates anchor links
          return (
            <li key={index}>
              <Link
                href={`#${link}`}
                sx={{ color: "#1e88e5", textDecoration: "underline" }}
              >
                {index + 1 + ". "}
                {section.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </Stack>
  );

  return (
    <Stack spacing={4}>
      {data.startingSection?.title && (
        <Typography
          variant="h4"
          sx={{ color: "#000", fontWeight: "bold", fontSize: 19 }}
        >
          {data.startingSection?.title}
        </Typography>
      )}
      {data.startingSection?.content &&
        renderContent(data.startingSection.content)}
      {data.toc && renderTOC()}
      {data.sections && data.sections.map(renderSection)}
    </Stack>
  );
};
