export const ROUTES = {
  index: "/",
  tickers: {
    index: "/tickers",
    byId: (id = ":id") => `/ticker/${id}`,
  },
  profile: {
    index: "/profile",
    byUsername: (username: string) => `/profile/${username}`,
  },
  terms_and_condition: {
    index: '/terms-and-conditions'
  },
  privacy_policy: {
    index: '/privacy-policy'
  },
  cookie_policy: {
    index: '/cookie-policy'
  },
  disclaimer: {
    index: '/disclaimer'
  },
  ticker_window_explanation: {
    index: '/ticker-window-explanation'
  },
  score_explanation: {
    index: '/score-explanation'
  },
  wildCard: "*"
};
