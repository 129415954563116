import React from "react";
import { CircularProgress } from "@mui/material";

const LoadingOverlay = ({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
        zIndex: 1000, 
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default LoadingOverlay;