import { PageType } from "../app/types/general";

export const docPagesLinks = [
    {
      title: "Terms & Conditions",
      link: "/terms-and-conditions",
      active: PageType.TERMS,
    },
    {
      title: "Privacy Policy",
      link: "/privacy-policy",
      active: PageType.PRIVACY,
    },
    {
      title: "Cookie Policy",
      link: "/cookie-policy",
      active: PageType.COOKIE,
    },
    {
      title: "Disclaimer",
      link: "/disclaimer",
      active: PageType.DISCLAIMER,
    },
    {
      title: "Ticker Window Explanation",
      link: "/ticker-window-explanation",
      active: PageType.TICKER,
    },
    {
      title: "Score Explanation",
      link: "/score-explanation",
      active: PageType.SCORE,
    },
  ];