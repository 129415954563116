import axios from "axios";
import { authSlice } from "../slices/authSlice";

export interface ErrorType {
  message: string;
  errors: {msg: string}[];
  status?: number;
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_EINSTEIN_URL,
});

// Add a request interceptor to include the token
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve token (e.g., from localStorage
    const token = localStorage.getItem("memeStockToken"); // Replace this with your token retrieval logic

    if (token) {
      // Attach token to Authorization header if available
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // If the response is 401 and the request is not already retried
    if (
      (!error.response?.status || error.response?.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true; // Mark the request as retried
      const { store } = await import("../store");
      try {
        const refreshToken = localStorage.getItem("memeStockRefreshToken");
        const username = store.getState().auth.userData?.username

        // Attempt to refresh the token
        const response = await axios.post(`${process.env.REACT_APP_BASE_EINSTEIN_URL}/api/refresh-session`, {
          username,
          refreshToken,
        });

        const {
          accessToken,
          idToken,
          refreshToken: newRefreshToken,
        } = response.data;

        store.dispatch(
          authSlice.actions.setTokens({
            idToken,
            accessToken,
            refreshToken: newRefreshToken || refreshToken,
          })
        );

        // Update the Authorization header for future requests
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${idToken}`;

        // Retry the original request with the new access token
        originalRequest.headers["Authorization"] = `Bearer ${idToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // Clear tokens from localStorage and Redux store
        store.dispatch(authSlice.actions.clearTokens());

        store.dispatch(authSlice.actions.setShowSignInPopup(true));

        return Promise.reject(refreshError);
      }
    }
    // Handle the error and pass the entire error response
    let errorData: ErrorType = {
      message: "An unknown error occurred",
      errors: [],
    };
    if (error.response) {
      // Check if the response has the specific error structure
      if (
        error.response.data.errors &&
        Array.isArray(error.response.data.errors)
      ) {
        errorData.errors = error.response.data.errors
      } else {
        // Default error message from response
        errorData.message =
          error.response.data.message ||
          error.response.data.msg ||
          errorData.message;
      }
    } else if (error.request) {
      // No response was received
      errorData.message = "No response received from server";
    } else {
      // Something else happened
      errorData.message = error.message;
    }

    return Promise.reject(errorData);
  }
);

export default axiosInstance;
