import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import LoadingOverlay from '../LoadingOverlay';
import { FormHelperText } from '@mui/material';
import ResetPasswordConfirm from './ResetPasswordConfirm';
import { ApiErrorResponse } from '../../app/types/general';
import { AuthState, resetPassword, authSlice } from '../../app/slices/authSlice';
import { PhoneInput } from '../SignUp/PhoneInput';
import { checkFormValid, handleBackendErrors, validateField } from '../../helpers/formHelpers';


const labelStyles = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    color: '#1E1F24',
};

export default function ResetPassword({ showSignIn }: any) {
    const initialFormData = {
        values: { phone: "", },
        errors: { phone: "", general: "" }
    };
    const dispatch = useAppDispatch();
    const { isLoading, errors, isResetPasswordOTPSent,  memeStockToken  } = useAppSelector((state) => state.auth) as AuthState;
    const [formData, setFormData] = React.useState(initialFormData);
    const [isFormValid, setIsFormValid] = React.useState(false);
    const [showResetPasswordConfirm, setShowResetPasswordConfirm] = React.useState(false);
    const [phone, setPhone] = React.useState("");


    const handlePhoneChange = (newValue: string) => {
        setPhone(newValue);
        // save value to formData
        setFormData({
            ...formData, values: {
                ...formData.values, phone: newValue,
            },
            errors: { ...formData.errors, phone: validateField("phone", newValue), general: "" }
        });
        setIsFormValid(checkFormValid(formData))
    };

    // if user click on Send button => OTP will be sent to use => show password reset component 
    const handleForgetSubmit = () => {
        dispatch(resetPassword({
            phone: formData.values.phone.replace(/\s+/g, ''), // remove any white space , 
        }))
    }





    React.useEffect(() => {
        setIsFormValid(checkFormValid(formData));
    }, [formData]);

    React.useEffect(() => {
        setShowResetPasswordConfirm(isResetPasswordOTPSent);
    }, [isResetPasswordOTPSent]);



    React.useEffect(() => {
        setFormData(({
            ...formData,
            errors: handleBackendErrors(errors as ApiErrorResponse, formData.errors) as any
        }));
    }, [errors]);

    React.useEffect(() => {
        setFormData(initialFormData);
        if (memeStockToken) {
            dispatch(authSlice.actions.clearPasswordResetState())
        }
        else {
            dispatch(authSlice.actions.resetState());
        }
    }, []);


    

    //showing  reset password  confirm with confirmation code component 
    if (showResetPasswordConfirm) {
        return (
            <>
                <ResetPasswordConfirm showSignIn={showSignIn} resendCode={handleForgetSubmit} phone={phone} />
            </>
        )
    }
    return (
        <Container disableGutters sx={{ px: '2rem !important' }} maxWidth={false}>
            <LoadingOverlay isLoading={isLoading} />
            <Box
                sx={{
                    px: 0,
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h1" sx={{
                    fontFamily: 'Sofia Pro',
                    fontSize: '2.375rem',
                    fontWeight: 500,
                    lineHeight: '2.8rem',
                }}>
                    Forget Password
                </Typography>
                <Typography component="h1" variant="h1" sx={{
                    fontFamily: 'Roboto',
                    fontSize: '1.1rem',
                    fontWeight: 400,
                    lineHeight: '2.8rem',
                    color: '#757575',
                }}>
                    Please Enter Your phone to reset your password
                </Typography>
                <Box sx={{ mt: 3 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h1" sx={labelStyles}>Phone</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneInput
                                id="outlined-error-helper-text"
                                error={!!formData.errors.phone}
                                value={phone}
                                fullWidth
                                name="phone"
                                forceCallingCode
                                helperText={formData.errors.phone}
                                onBlur={(e) => setFormData({ ...formData, errors: { ...formData.errors, phone: validateField(e.target.name, phone), general: "" } })}
                                onChange={handlePhoneChange}
                                defaultCountry='US'
                            />
                        </Grid>
                    </Grid>
                    <Typography component="h1" variant="h1" sx={{
                        fontFamily: 'Roboto',
                        fontSize: '.85rem',
                        fontWeight: 400,
                        lineHeight: '2.8rem',
                    }}>
                        A confirmation code will be sent to your phone number
                    </Typography>
                    <Button
                        onClick={() => { handleForgetSubmit() }}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2, mb: 2 }}
                        disabled={!isFormValid}
                    >
                        Send
                    </Button>
                </Box>
            </Box>

            <FormHelperText sx={{ color: 'red', fontFamily: "Roboto", fontSize: '1rem', textAlign: 'center' }}>{formData.errors.general}</FormHelperText>

        </Container>
    );
}