export interface TickerObject {
  symbol: string;
  sentiment: Sentiment;
  price: number;
  contributorCount: number;
  submissionCount: number;
  symbolLabel?: string;
  name: string;
  targetPrice: number;
  targetDate: string;
}

export type MarketType = "crypto" | "stocks";

export type Sentiment =
  | "really_bullish"
  | "bullish"
  | "neutral"
  | "bearish"
  | "really_bearish";

export type SortField =
  | "bullish"
  | "bearish"
  | "uniquePredictors"
  | "predictionsMade";

export enum ITickerIntervalsEnum {
  ONE_WEEK = "one_week",
  ONE_MONTH = "one_month",
  THREE_MONTHS = "three_months",
  SIX_MONTHS = "six_months",
  ONE_YEAR = "one_year",
}

export type ITickerDisplayData = {
  date: string;
} & Omit<Partial<Record<string, string>>, "date">;

export enum ChartTypeEnum {
  "line" = "line",
}

export enum ChartLineTypeEnum {
  "solid" = "solid",
  "dashed" = "dashed",
}

export interface TickerChartConfigItem {
  lineType: ChartLineTypeEnum;
  type: ChartTypeEnum;
  color: string;
  label: string;
  editable?: boolean;
}

export interface ITickerDetails {
  symbol: string;
  symbolLabel: string | null;
  disableSubmitButton?: boolean;
  disableEditButton?: boolean;
  currentCollectiveSentimentPrice?: string;
  currentTradingDay: string;
  name: string;
  sentiment: Sentiment;
  currentPrice: string;
  collectiveSentimentPrice: string;
  totalContributorCount: number;
  totalSubmissionCount: number;
  userSentimentPrice?: string;
  todayCollectiveSentimentPrice: string;
  collectiveScore: number;
  displayData: ITickerDisplayData[];
  chartConfig: {
    dateFormat: string;
  } & Record<string, TickerChartConfigItem | string>;
}

export interface IStrikeZone {
  date: string;
  highest: number;
  lowest: number;
  reallyBullishPrice: number;
  bullishPrice: number;
  neutralPrice: number;
  bearishPrice: number;
  reallyBearishPrice: number;
}

export interface ITickerWindowLimits {
  strikeZone: IStrikeZone[];
  newPredictionLine: {
    fromDate: string;
    fromPrice: string;
    toDate: string;
    toPrice: string;
  };
}

export interface ITickerEditWindowLimits {
  strikeZone: IStrikeZone[];
}
