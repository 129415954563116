import { cookiePolicy } from "../../constants/data";
import { PageType } from "../../app/types/general";
import Content from "../../components/Content";

const CookiePolicy = () => {
  return (
    <Content
      title="Cookie Policy"
      subtitles={[
        "What Are Cookies?",
        "Why Do We Use Cookies?",
      ]}
      activePage={PageType.COOKIE}
      textData={cookiePolicy}
    />
  );
};

export default CookiePolicy;
