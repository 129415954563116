import { privacyPolicy } from "../../constants/data";
import { PageType } from "../../app/types/general";
import Content from "../../components/Content";

const PrivacyPolicy = () => {
  return (
    <Content
      title="Privacy Policy"
      subtitles={[
        "How We Protect Your Information",
        "Our Commitment to Your Privacy",
      ]}
      activePage={PageType.PRIVACY}
      textData={privacyPolicy}
    />
  );
};

export default PrivacyPolicy;
