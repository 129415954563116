import { termsAndCondition } from "../../constants/data";
import Content from "../../components/Content";
import { PageType } from "../../app/types/general";

const TermsAndConditions = () => {
  return (
    <Content
      title="Terms & Conditions"
      subtitles={[
        "Your Agreement for Using Memstock",
        "Understanding Your Rights and Responsibilities",
      ]}
      activePage={PageType.TERMS}
      textData={termsAndCondition}
    />
  );
};

export default TermsAndConditions;
