import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { PageType } from "../app/types/general";
import { docPagesLinks } from "../constants/links";

const activeBtnStyle = {
  fontSize: "14px",
  color: "white",
  backgroundColor: "blue",
  borderRadius: ".5rem",
  "&:hover": {
    backgroundColor: "primary", // Optional: Hover effect
  },
};
const nonActiveBtnStyle = {
  fontSize: "14px",
  color: "#212121",
  borderRadius: ".5rem",
  borderColor: "#E0E0E0",
};


const TermsPrivacyBtnGroup = ({ active }: { active: `${PageType}` }) => {
  return (
    <Grid
      xs={12}
      container
      spacing={4}
      sx={{
        placeSelf: "center",
        justifyContent: "center",
        marginTop: "10rem",
        gap: "1.5rem",
      }}
    >
      {docPagesLinks.map((item) => (
        <Link key={item.link} to={{ pathname: item.link }}>
          <Button
            sx={active === item.active ? activeBtnStyle : nonActiveBtnStyle}
            variant={active === item.active ? "contained" : "outlined"}
          >
            {item.title}
          </Button>
        </Link>
      ))}
    </Grid>
  );
};

export default TermsPrivacyBtnGroup;
