import { ticketWindowsExplanation } from "../../constants/data";
import Content from "../../components/Content";
import { PageType } from "../../app/types/general";

const TickerWindowExplanation = () => {
  return (
    <Content
      title="Ticker Window Explanation"
      subtitles={[
        "Overview of ticker features and tools for tracking real-time crypto data",
        "Tips for customizing the ticker window for better usability",
      ]}
      activePage={PageType.TICKER}
      textData={ticketWindowsExplanation}
    />
  );
};

export default TickerWindowExplanation;
