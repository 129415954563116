import { disclaimer } from "../../constants/data";
import { PageType } from "../../app/types/general";
import Content from "../../components/Content";

const Disclaimer = () => {
  return (
    <Content
      title="Disclaimer"
      subtitles={[
        "Acknowledgment of the Disclaimer Terms",
        "User Responsibilities and Conduct",
      ]}
      activePage={PageType.DISCLAIMER}
      textData={disclaimer}
    />
  );
};

export default Disclaimer;
