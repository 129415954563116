import { configureStore } from "@reduxjs/toolkit";
import authReducer from './slices/authSlice';
import tickerReducer from './slices/tickerSlice';
import toastReducer from './slices/toastSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    tickers: tickerReducer,
    toast: toastReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
