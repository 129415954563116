import { matchIsValidTel } from 'mui-tel-input';
import { ApiErrorResponse } from '../app/types/general';

export const validateField = (field: string, value: string): string => {
    let errorMsg = '';
    if (field === "phone" && !matchIsValidTel(value, { onlyCountries: ["US"] })) {
        errorMsg = "Invalid phone number";
    } else if (!(value?.toString()?.trim())) {
        errorMsg = field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim();
    }
    return errorMsg;
};

export const isValidForm = (
    formData: { values: object, errors: object }
): boolean => {
    return Object.values(formData?.values)?.every((value: string) => value && value?.toString()?.trim() !== '') &&
        !Object.values(formData.errors).some((error) => error?.length > 0);
};


export const checkFormValid = (
    formData: { values: object, errors: object }
): boolean => {
    if ((formData.values as any)?.acceptTerms == undefined) {
        (formData.values as any).acceptTerms = true
    }
    return isValidForm(formData) && (formData.values as any).acceptTerms;
};

export const handleBackendErrors = (
    errors: ApiErrorResponse,
    currentErrors: { [key: string]: string }
): { [key: string]: string } => {
    const updatedErrors = { ...currentErrors };

    if (errors?.length) {
        errors.forEach(({ path, msg }) => {
            if (updatedErrors.hasOwnProperty(path as string)) {
                updatedErrors[path] = msg;
            } else {
                updatedErrors.general = msg;
            }
        });
    }

    return updatedErrors;
};