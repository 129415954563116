import React from 'react'
import { MuiTelInput, MuiTelInputProps, } from 'mui-tel-input';

export const PhoneInput = (props: MuiTelInputProps) => {
    return (
        <MuiTelInput
            fullWidth
            disableDropdown={true}
            onlyCountries={["US"]}
            {...props}
            defaultCountry='US'
            variant="outlined"
            sx={{
                '& .MuiInputBase-root': {
                    borderRadius: '0.5rem',
                    border: '1px solid #CDCED7 ',
                    height: '2.5rem',
                    width: '100%'
                },
                '.MuiTelInput-TextField': {
                    borderRadius: '0.5rem',
                    border: '1px solid #CDCED7 ',
                    height: '2.5rem',
                    width: '100%'
                },
            }}
        />
    )
}
