import Header from "../../components/Header/Header";
import CustomContainer from "../../components/CustomContainer";
import { Grid, Typography } from "@mui/material";
import { FontsFamily } from "../../constants/fonts-enum";
import { theme } from "../../app/theme";
import TextCard from "../../components/ui/TextCard";
import TermsPrivacyBtnGroup from "../../components/TermsPrivacyBtnGroup";
import backgroundImage from "../../assets/images/background.png";
import { PageType } from "../../app/types/general";
import { RichTextParser } from "../../helpers/contentParser";

interface ContentProps {
  title: string;
  subtitles: string[];
  textData: any;
  activePage?: `${PageType}`;
}
const Content = ({ title, subtitles, textData, activePage }: ContentProps) => {
  return (
    <div>
      <Header
        sx={{
          height: "530px",
          backgroundImage: `url('${backgroundImage}')`,
          backgroundColor: "transparent",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "0% 100%",
        }}
      />
      <CustomContainer sx={{ gap: "3rem", marginTop: "-20rem" }}>
        <TextCard title={title} paras={subtitles} />
        {activePage && <TermsPrivacyBtnGroup active={activePage} />}
        <Grid
          xs={12}
          container
          sx={{
            justifyContent: "center",
            paddingTop: "4rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              fontWeight: "700",
              fontFamily: FontsFamily.Roboto,
              fontSize: theme.spacing(4),
              color: "#20184E",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid container sx={{ maxWidth: "1000px", placeSelf: "center" }}>
        
          <RichTextParser data={textData} />
        </Grid>
      </CustomContainer>
    </div>
  );
};

export default Content;
