import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CustomTextField from '../CustomTextField/CustomTextField';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import LoadingOverlay from '../LoadingOverlay';
import { FormHelperText } from '@mui/material';
import { AuthState, resetPasswordConfirm } from '../../app/slices/authSlice';
import { ApiErrorResponse, ErrorObject } from '../../app/types/general';
import handleShowPasswordError from '../../helpers/handleShowPasswordError';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { checkFormValid, handleBackendErrors, validateField } from '../../helpers/formHelpers';


const labelStyles = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    color: '#1E1F24',
};


export default function ResetPasswordConfirm({ showSignIn, phone, resendCode }: any) {
    const dispatch = useAppDispatch();
    const { isLoading, errors } = useAppSelector((state) => state.auth) as AuthState;
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showNewPasswordConfirm, setShowNewPasswordConfirm] = React.useState(false);
    const [confirmFormData, setConfirmFormData] = React.useState({
        values: { newPassword: "", confirmedNewPassword: "", confirmationCode: "" },
        errors: { newPassword: "", confirmedNewPassword: "", confirmationCode: "", general: "" }
    });
    const [isFormValid, setIsFormValid] = React.useState(false);


    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setConfirmFormData((prevData) => ({
            ...prevData,
            values: {
                ...prevData.values,
                [name]: value,
            },
            errors: { ...prevData.errors, [name]: validateField(name, value), general: "" }
        }));
    };

    React.useEffect(() => {
        setIsFormValid(checkFormValid(confirmFormData));
    }, [confirmFormData]);


    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const handleClickShowNewPasswordConfirm = () => {
        setShowNewPasswordConfirm(!showNewPasswordConfirm);
    };

    //  to keep focus and cursor working well when press on show password icon
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleConfirmationCodeChange = (newValue: string) => {
        setConfirmFormData({ ...confirmFormData, values: { ...confirmFormData.values, confirmationCode: newValue } })
        setConfirmFormData((prevData) => ({
            ...prevData,
            values: {
                ...prevData.values,
                confirmationCode: newValue,
            },
            errors: { ...prevData.errors, confirmationCode: validateField("confirmationCode", newValue), general: "" }
        }));
    }




    const handleResetPasswordSubmit = () => {
        dispatch(resetPasswordConfirm({
            phone: phone.replace(/\s+/g, ''), // remove any white space , ,
            newPassword: confirmFormData.values.newPassword,
            confirmedNewPassword: confirmFormData.values.confirmedNewPassword,
            confirmationCode: confirmFormData.values.confirmationCode
        }))
    }


    React.useEffect(() => {
        setConfirmFormData(({
            ...confirmFormData,
            errors: handleBackendErrors(errors as ApiErrorResponse, confirmFormData.errors) as any
        }));
    }, [errors]);




    return (
        <Container disableGutters sx={{ px: '2rem !important' }} maxWidth={false}>
            <LoadingOverlay isLoading={isLoading} />
            <Box
                sx={{
                    px: 0,
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h1" sx={{
                    fontFamily: 'Sofia Pro',
                    fontSize: '2.375rem',
                    fontWeight: 500,
                    lineHeight: '2.8rem',
                }}>
                    Reset Password
                </Typography>
                <Box sx={{ mt: 3 }}>
                    <Grid container spacing={1}>

                        {/* ----------- new password ------------ */}
                        <Grid item xs={12}>
                            <Typography variant="h1" sx={labelStyles}>New Password</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                error={!!confirmFormData.errors.newPassword}
                                id="outlined-error-helper-text"
                                fullWidth
                                name="newPassword"
                                autoComplete="password"
                                type={showNewPassword ? 'text' : 'password'}
                                helperText={handleShowPasswordError(confirmFormData.errors.newPassword)}
                                onChange={handleFormChange}
                                onBlur={handleFormChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>


                        {/* ----------- confirm new password ------------ */}

                        <Grid item xs={12}>
                            <Typography variant="h1" sx={labelStyles}>Confirm New Password</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                error={!!confirmFormData.errors.confirmedNewPassword}
                                id="outlined-error-helper-text"
                                fullWidth
                                name="confirmedNewPassword"
                                autoComplete="password"
                                type={showNewPasswordConfirm ? 'text' : 'password'}
                                helperText={handleShowPasswordError(confirmFormData.errors.confirmedNewPassword)}
                                onChange={handleFormChange}
                                onBlur={handleFormChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPasswordConfirm}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showNewPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        {/* ----------confirmation code ---------- */}
                        <Grid item xs={12}>
                            <Typography variant="h1" sx={labelStyles}>Confirmation Code</Typography>
                        </Grid>
                        <Box>
                            <MuiOtpInput
                                length={6}
                                autoFocus
                                sx={{ width: "90%", height: 'auto', m: "1rem" }}
                                value={confirmFormData.values.confirmationCode}
                                TextFieldsProps={{
                                    error:!!confirmFormData.errors.confirmationCode,
                                    autoComplete: "confirmationCode",
                                    onBlur: (e) => handleFormChange(e),
                                }}

                                onChange={(newValue) => handleConfirmationCodeChange(newValue)}
                            />
                            {!!confirmFormData.errors.confirmationCode && (
                                <Typography  color="error" sx={{fontSize:"0.75rem",mx:"1rem"}}>
                                    {confirmFormData.errors.confirmationCode}
                                </Typography>)}
                        </Box>

                    </Grid>

                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                        <Typography component="h1" variant="h1" sx={{
                            fontFamily: 'Roboto',
                            fontSize: '1rem',
                            fontWeight: 400,
                            lineHeight: '2.8rem',
                            cursor: "pointer",
                            color: '#0062FF',
                            textAlign: 'end',
                            display: "inline"
                        }}
                            onClick={resendCode}
                        >

                            Resend
                        </Typography>
                    </Box>

                    <Button
                        onClick={handleResetPasswordSubmit}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2, mb: 2 }}
                        disabled={!isFormValid}
                    >
                        Send
                    </Button>
                </Box>
            </Box>

            <FormHelperText sx={{ color: 'red', fontFamily: "Roboto", fontSize: '1rem', textAlign: 'center' }}>{confirmFormData.errors.general}</FormHelperText>

        </Container>
    );
}