import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  let memeStockToken = useAppSelector(
    (state: any) => state.auth.memeStockToken
  );
  if (!memeStockToken) {
    memeStockToken = localStorage.getItem("memeStockToken");
  }

  if (memeStockToken) {
    return <>{children}</>;
  }

  return <Navigate to="/" />; // TODO: need to create route and component for it
};

export default PrivateRoute;
