import * as React from "react";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomTextField from "../CustomTextField/CustomTextField";
import { PhoneInput } from "./PhoneInput";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  authSlice,
  AuthState,
  signUp,
  verifyOtp,
} from "../../app/slices/authSlice";
import { SignUpPayload } from "../../app/types/auth";
import { MuiOtpInput } from "mui-one-time-password-input";
import { ApiErrorResponse } from "../../app/types/general";
import { FormHelperText } from "@mui/material";
import LoadingOverlay from "../LoadingOverlay";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import handleShowPasswordError from "../../helpers/handleShowPasswordError";
import {
  checkFormValid,
  handleBackendErrors,
  validateField,
} from "../../helpers/formHelpers";
import "./SignUp.css";
import { Link } from "react-router-dom";

const labelStyles = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "1rem",
  color: "#1E1F24",
};

export default function SignUp({ handleOpenSignIn, handleClose }: any) {
  const initialFormData = {
    values: {
      name: "",
      username: "",
      email: "",
      password: "",
      phone: "",
    },
    errors: {
      name: "",
      username: "",
      email: "",
      password: "",
      phone: "",
      general: "",
    },
  };
  const [formData, setFormData] = React.useState(initialFormData);

  const [otp, setOtp] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [acceptDisclaimer, setAcceptDisclaimer] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isFormValid, setIsFormValid] = React.useState(false);
  const dispatch = useAppDispatch();

  const handlePhoneChange = (newValue: string) => {
    setPhone(newValue);
    // save value to formData
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        phone: newValue,
      },
      errors: {
        ...formData.errors,
        phone: validateField("phone", newValue),
        general: "",
      },
    });
    setIsFormValid(checkFormValid(formData));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  //  to keep focus and cursor working well when press on show password icon
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const {
    data: fjsData,
    isLoading: isVisitorLoading,
    getData: getVisitorData,
  } = useVisitorData({ extendedResult: false }, { immediate: true });

  React.useEffect(() => {
    const fpjsCheck = async () => {
      if (!fjsData?.requestId && !isVisitorLoading) {
        //if request id is still undefined then the user is using
        //some kind of ad blocker to prevent fingerprint js to take
        //metrics of the user's browser. We should display some kind
        //of alert to let the user know
        await getVisitorData();
      }
    };
    fpjsCheck();
  }, [fjsData, isVisitorLoading]);

  const handleVerifyOtpSubmit = () => {
    dispatch(
      verifyOtp({
        requestId: fjsData?.requestId as string,
        password: formData.values.password,
        verificationCode: otp,
      })
    );
  };

  const handleFormChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      values: {
        ...prevData.values,
        [name]: value,
      },
      errors: {
        ...prevData.errors,
        [name]: validateField(name, value),
        general: "",
      },
    }));
  };

  React.useEffect(() => {
    setIsFormValid(checkFormValid(formData) && acceptTerms && acceptDisclaimer);
  }, [formData, acceptTerms, phone, acceptDisclaimer]);

  const handleSignUpSubmit = () => {
    const { values } = formData;
    const signUpData: SignUpPayload = {
      email: values.email,
      password: values.password,
      phone: phone.replace(/\s+/g, ""), // remove any white space
      name: values.name,
      username: values.username,
      requestId: fjsData?.requestId as string,
    };
    dispatch(signUp(signUpData));
  };

  const { isLoading, errors, isOtpSent } = useAppSelector(
    (state) => state.auth
  ) as AuthState;

  React.useEffect(() => {
    setFormData({
      ...formData,
      errors: handleBackendErrors(
        errors as ApiErrorResponse,
        formData.errors
      ) as any,
    });
  }, [errors]);

  React.useEffect(() => {
    setFormData(initialFormData);
    dispatch(authSlice.actions.resetState());
  }, []);

  return (
    <Container disableGutters sx={{ px: 0 }} maxWidth="xs">
      <LoadingOverlay isLoading={isLoading} />
      {isOtpSent ? (
        <Box>
          <MuiOtpInput
            length={6}
            autoFocus
            value={otp}
            onChange={(newValue) => setOtp(newValue)}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            disabled={!(otp.length === 6)}
            onClick={handleVerifyOtpSubmit}
          >
            Verify OTP
          </Button>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleSignUpSubmit}>resend OTP</Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h1"
            sx={{
              fontFamily: "Sofia Pro",
              fontSize: "2.375rem",
              fontWeight: 500,
              lineHeight: "2.8rem",
            }}
          >
            Create Account
          </Typography>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              fontFamily: "Roboto",
              fontSize: "1.1rem",
              fontWeight: 400,
              lineHeight: "2.8rem",
              color: "#757575",
            }}
          >
            Get started today!
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h1" sx={labelStyles}>
                  Name
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  name="name"
                  placeholder="Name"
                  helperText={formData.errors.name}
                  error={!!formData.errors.name}
                  onChange={handleFormChange}
                  onBlur={handleFormChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h1" sx={labelStyles}>
                  Username
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  error={!!formData.errors.username}
                  id="outlined-error-helper-text"
                  fullWidth
                  name="username"
                  placeholder="Username"
                  helperText={formData.errors.username}
                  onChange={handleFormChange}
                  onBlur={handleFormChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h1" sx={labelStyles}>
                  Email
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  error={!!formData.errors.email}
                  id="outlined-error-helper-text"
                  fullWidth
                  name="email"
                  autoComplete="email"
                  placeholder="name@mail.com"
                  helperText={formData.errors.email}
                  onChange={handleFormChange}
                  onBlur={handleFormChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h1" sx={labelStyles}>
                  Phone
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  id="outlined-error-helper-text"
                  error={!!formData.errors.phone}
                  value={phone}
                  fullWidth
                  name="phone"
                  forceCallingCode
                  helperText={formData.errors.phone}
                  onBlur={(e) =>
                    setFormData({
                      ...formData,
                      errors: {
                        ...formData.errors,
                        phone: validateField(e.target.name, phone),
                        general: "",
                      },
                    })
                  }
                  onChange={handlePhoneChange}
                  defaultCountry="US"
                />
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: ".8rem", color: "grey" }}
                >
                  A verification code will be sent to this number
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h1" sx={labelStyles}>
                  Password
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  error={!!formData.errors.password}
                  id="outlined-error-helper-text"
                  fullWidth
                  name="password"
                  autoComplete="password"
                  type={showPassword ? "text" : "password"}
                  helperText={handleShowPasswordError(formData.errors.password)}
                  onChange={handleFormChange}
                  onBlur={handleFormChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="acceptTerms"
                      color="primary"
                      name="acceptTerms"
                      checked={acceptTerms}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setAcceptTerms(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: 16 }}>
                      I agree with{" "}
                      <Link to={{ pathname: "/terms-and-conditions" }}>
                        Terms and Condition
                      </Link>
                      /
                      <Link to={{ pathname: "/privacy-policy" }}>
                        Privacy Policy
                      </Link>
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="acceptDisclaimer"
                      color="primary"
                      name="acceptDisclaimer"
                      checked={acceptDisclaimer}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setAcceptDisclaimer(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: 16 }}>
                      I confirm that I understand and agree to the
                      <Link to={{ pathname: "/disclaimer" }}> Disclaimer</Link>
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <Button
              onClick={handleSignUpSubmit}
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              disabled={!isFormValid}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <FormHelperText
                  sx={{
                    color: "#757575",
                    fontFamily: "Roboto",
                    fontSize: "0.85rem",
                  }}
                >
                  Already have an account?
                  <Box
                    component="a"
                    onClick={handleOpenSignIn}
                    sx={{
                      px: 1,
                      color: "#1E1F24",
                      fontFamily: "Roboto",
                      textTransform: "none",
                      background: "none",
                      cursor: "pointer",
                      fontSize: "1rem",
                      textDecoration: "none",
                    }}
                  >
                    Sign in
                  </Box>
                </FormHelperText>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      <FormHelperText
        sx={{
          color: "red",
          fontFamily: "Roboto",
          fontSize: "1rem",
          textAlign: "center",
        }}
      >
        {formData.errors.general}
      </FormHelperText>
    </Container>
  );
}
