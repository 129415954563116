export interface ErrorObject {
  type?: string | null;
  location?: string | null;
  value?: string | null;
  path: string;
  msg: string;
}

export type ApiErrorResponse = Array<ErrorObject>;

export enum PageType {
  TERMS = "TERMS",
  PRIVACY = "PRIVACY",
  COOKIE = "COOKIE",
  DISCLAIMER = "DISCLAIMER",
  SCORE = "SCORE",
  TICKER = "TICKER",
}
