import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomTextField from "../CustomTextField/CustomTextField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AuthState, signIn, authSlice } from "../../app/slices/authSlice";
import { SignInPayload } from "../../app/types/auth";
import { ApiErrorResponse } from "../../app/types/general";
import { FormHelperText } from "@mui/material";
import LoadingOverlay from "../LoadingOverlay";
import ResetPassword from "../ResetPassword/ResetPassword";
import { PhoneInput } from "../SignUp/PhoneInput";
import {
  checkFormValid,
  handleBackendErrors,
  validateField,
} from "../../helpers/formHelpers";

const labelStyles = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "1rem",
  color: "#1E1F24",
};

export default function SignIn({ handleOpenSignUp, handleClose }: any) {
  const initialFormData = {
    values: { phone: "", password: "" },
    errors: { phone: "", password: "", general: "" },
  };
  const [formData, setFormData] = React.useState(initialFormData);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showResetPassword, setShowResetPassword] = React.useState(false);
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [phone, setPhone] = React.useState("");

  const dispatch = useAppDispatch();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  React.useEffect(() => {
    setFormData({
      values: { phone: "", password: "" },
      errors: { phone: "", password: "", general: "" },
    });
  }, [showResetPassword]);

  const showSignIn = () => {
    setShowResetPassword(false);
  };

  //  to keep focus and cursor working well when press on show password icon
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const { name, value } = event.target;
    validateField(name, value);
    setFormData((prevData) => ({
      ...prevData,
      values: {
        ...prevData.values,
        [name]: value,
      },
      errors: {
        ...prevData.errors,
        [name]: validateField(name, value),
        general: "",
      },
    }));
  };

  const handlePhoneChange = (newValue: string) => {
    setPhone(newValue);
    // save value to formData
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        phone: newValue,
      },
      errors: {
        ...formData.errors,
        phone: validateField("phone", newValue),
        general: "",
      },
    });
    setIsFormValid(checkFormValid(formData));
  };

  const handleSignInSubmit = () => {
    const { values } = formData;
    const signInData: SignInPayload = {
      phone: values.phone.replace(/\s+/g, ""), // remove any white space ,
      password: values.password,
    };
    dispatch(signIn(signInData));
  };

  const { isLoading, errors, isPasswordReset } = useAppSelector(
    (state) => state.auth
  ) as AuthState;

  React.useEffect(() => {
    setIsFormValid(checkFormValid(formData));
  }, [formData]);

  React.useEffect(() => {
    setFormData({
      ...formData,
      errors: handleBackendErrors(
        errors as ApiErrorResponse,
        formData.errors
      ) as any,
    });
  }, [errors]);

  React.useEffect(() => {
    if (isPasswordReset) showSignIn();
  }, [isPasswordReset]);

  React.useEffect(() => {
    setFormData(initialFormData);
    dispatch(authSlice.actions.resetState());
  }, []);

  if (showResetPassword) {
    return (
      <>
        <ResetPassword />
      </>
    );
  }

  return (
    <Container disableGutters sx={{ px: "2rem !important" }} maxWidth={false}>
      <LoadingOverlay isLoading={isLoading} />

      <Box
        sx={{
          px: 0,
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h1"
          sx={{
            fontFamily: "Sofia Pro",
            fontSize: "2.375rem",
            fontWeight: 500,
            lineHeight: "2.8rem",
          }}
        >
          Welcome Back,
        </Typography>
        <Typography
          component="h1"
          variant="h1"
          sx={{
            fontFamily: "Roboto",
            fontSize: "1.1rem",
            fontWeight: 400,
            lineHeight: "2.8rem",
            color: "#757575",
            textAlign: "center",
          }}
        >
          {isPasswordReset && (
            <>
              Password change succeeded
              <br />
            </>
          )}
          Enter your Phone and password to Sign In.
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h1" sx={labelStyles}>
                Phone
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PhoneInput
                id="outlined-error-helper-text"
                error={!!formData.errors.phone}
                value={phone}
                fullWidth
                name="phone"
                forceCallingCode
                helperText={formData.errors.phone}
                onBlur={(e) =>
                  setFormData({
                    ...formData,
                    errors: {
                      ...formData.errors,
                      phone: validateField(e.target.name, phone),
                      general: "",
                    },
                  })
                }
                onChange={handlePhoneChange}
                defaultCountry="US"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h1" sx={labelStyles}>
                Password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                error={!!formData.errors.password}
                id="outlined-error-helper-text"
                fullWidth
                name="password"
                autoComplete="password"
                type={showPassword ? "text" : "password"}
                helperText={formData.errors.password}
                onChange={handleFormChange}
                onBlur={handleFormChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <Typography
              variant="inherit"
              sx={{
                fontFamily: "Roboto",
                fontSize: "1rem",
                fontWeight: 400,
                lineHeight: "2.8rem",
                cursor: "pointer",
                color: "#0062FF",
                textAlign: "end",
                display: "inline",
              }}
              onClick={() => setShowResetPassword(true)}
            >
              Forgot Password?
            </Typography>
          </Box>
          <Button
            onClick={handleSignInSubmit}
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            disabled={!isFormValid}
          >
            Sign In
          </Button>
          <Grid container justifyContent="center">
            <Grid item>
              <FormHelperText sx={{ color: "#757575", fontFamily: "Roboto" }}>
                Not registered?
                <Box
                  component="a"
                  onClick={handleOpenSignUp}
                  sx={{
                    px: 1,
                    color: "#1E1F24",
                    fontFamily: "Roboto",
                    textTransform: "none",
                    background: "none",
                    cursor: "pointer",
                    fontSize: "1rem",
                    textDecoration: "none",
                  }}
                >
                  Create account
                </Box>
              </FormHelperText>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <FormHelperText
        sx={{
          color: "red",
          fontFamily: "Roboto",
          fontSize: "1rem",
          textAlign: "center",
        }}
      >
        {formData.errors.general}
      </FormHelperText>
    </Container>
  );
}
