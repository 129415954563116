import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import Toast from "./ui/Toast";
import AuthDialog from "./Auth/AuthDialog";
import { useAuthDialog } from "../context/auth-dialog-context";
import { docPagesLinks } from "../constants/links";

function ScrollToTop() {
  const { pathname } = useLocation();
  const { handleClose } = useAuthDialog();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (docPagesLinks.some((item) => item.link === pathname)) {
      handleClose();
    }
  }, [pathname]);

  return null;
}

const RootLayout = () => {
  return (
    <Fragment>
      <ScrollToTop />
      <Toast />
      <AuthDialog />
      <Outlet />
    </Fragment>
  );
};

export default RootLayout;
