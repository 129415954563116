import { scoreExplanation } from "../../constants/data";
import Content from "../../components/Content";
import { PageType } from "../../app/types/general";

const ScoreExplanation = () => {
  return (
    <Content
      title="Score Explanation"
      subtitles={[
        "Explanation of score calculation and leaderboard ranking",
        "Simple strategies to improve your score through accurate predictions.",
      ]}
      activePage={PageType.SCORE}
      textData={scoreExplanation}
    />
  );
};

export default ScoreExplanation;
