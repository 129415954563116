import React, { useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { addToast, IToast, removeToast } from '../../app/slices/toastSlice';
import { ErrorType } from '../../app/api/axiosInstance';

export const errorToastData = (message: string): Omit<IToast, 'id'> => ({ message, severity: 'error', duration: 2000 })
export const successToastData = (message: string): Omit<IToast, 'id'> => ({ message, severity: 'success', duration: 2000 })

export const useToast = () => {
    const dispatch = useAppDispatch();

    const toast = useCallback((data: Omit<IToast, 'id'>) => dispatch(addToast(data)), [dispatch]);

    const errorToast = useCallback((error: ErrorType) => {
        if (error.errors?.length) {
            error.errors.forEach(({msg}) => toast(errorToastData(msg)))
        } else {
            toast(errorToastData(error.message))
        }
    }, [toast])
    const successToast = useCallback((message: string) => toast(successToastData(message)), [toast])

    return ({
        toast,
        errorToast,
        successToast
    })
}

const Toast: React.FC = () => {
    const dispatch = useAppDispatch();
    const toasts = useAppSelector((state) => state.toast.toasts);

    return (
        <>
            {toasts.map((toast) => (
                <Snackbar
                    key={toast.id}
                    open
                    autoHideDuration={toast.duration || 3000}
                    onClose={() => dispatch(removeToast(toast.id))}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert
                        onClose={() => dispatch(removeToast(toast.id))}
                        severity={toast.severity}
                        sx={{ width: '100%' }}
                    >
                        {toast.message}
                    </Alert>
                </Snackbar>
            ))}
        </>
    );
};

export default Toast;