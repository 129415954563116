import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import PropTypes from 'prop-types';

const CustomTextField = ({ ...props }: TextFieldProps) => {
    return (
        <TextField
            FormHelperTextProps={{ sx: { paddingY: '2px' } }}
            variant="outlined"
            {...props}
            sx={{
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    border: '1px solid #CDCED7',
                    height: '2.5rem',
                    '&.Mui-focused': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                },
                ...props.sx

            }}
        />
    );
};

CustomTextField.propTypes = {
    borderRadius: PropTypes.string,
};

export default CustomTextField;