import { ISearchTicker, ISearchUser } from "../../app/types/search";
import { mock } from "../mock";


function createUserData({ label, pathId, name, type }: ISearchUser): ISearchUser {
    return { label, pathId, name, type };
}
function createTickerData({ label, pathId, name }: ISearchTicker): any {
    return { label, pathId, name };
}

const userRows: Array<ISearchUser> = [
    createUserData({ label: 'usr1', pathId: '1', name: 'User 1', type: "user" }),
    createUserData({ label: 'usr2', pathId: '2', name: 'User 2', type: "user" }),
    createUserData({ label: 'usr3', pathId: '3', name: 'User 3', type: "user" }),
    createUserData({ label: 'usr4', pathId: '4', name: 'User 4', type: "user" }),
    createUserData({ label: 'usr5', pathId: '5', name: 'User 5', type: "user" }),
];


const cryptoTrickersRows: Array<ISearchTicker> = [
    createTickerData({ label: 'crypt1', pathId: 'GEM', name: "Crypto 1", type: "crypto" }),
    createTickerData({ label: 'crypt2', pathId: 'GEM', name: "Crypto 2", type: "crypto" }),
    createTickerData({ label: 'crypt3', pathId: 'GEM', name: "Crypto 3", type: "crypto" }),
    createTickerData({ label: 'crypt4', pathId: 'GEM', name: "Crypto 4", type: "crypto" }),
    createTickerData({ label: 'crypt5', pathId: 'GEM', name: "Crypto 5", type: "crypto" }),
    createTickerData({ label: 'crypt6', pathId: 'MSFT', name: "Crypto 6", type: "crypto" }),
];

const stockTrickersRows: Array<ISearchTicker> = [
    createTickerData({ label: 'stk1', pathId: 'AAPL', name: "Stock 1", type: "stocks" }),
    createTickerData({ label: 'stk2', pathId: 'GEM', name: "Stock 2", type: "stocks" }),
    createTickerData({ label: 'stk3', pathId: 'AMZN', name: "Stock 3", type: "stocks" }),
    createTickerData({ label: 'stk4', pathId: 'AMZN', name: "Stock 4", type: "stocks" }),
    createTickerData({ label: 'stk5', pathId: 'AMZN', name: "Stock 5", type: "stocks" }),
    createTickerData({ label: 'stk6', pathId: 'MSFT', name: "Stock 6", type: "stocks" }),
];


mock.onGet(new RegExp(`/api/search/*`)).reply(() => {
    return [200, {
        users: userRows,
        stocks: stockTrickersRows,
        crypto: cryptoTrickersRows,
    }]
});
