import { createBrowserRouter, Navigate, useParams } from "react-router-dom";
import { ROUTES } from "./routes";
import GlobalLoader from "../components/GlobalLoader";
import { lazy } from "react";
import PrivateRoute from "../components/PrivateRoute";
import { useAppSelector } from "./hooks";
import RootLayout from "../components/RootLayout";
import TermsAndConditions from "../pages/terms-and-conditions";
import PrivacyPolicy from "../pages/privacy-policy";
import TickerWindowExplanation from "../pages/ticker-window-explaination";
import ScoreExplanation from "../pages/score-exaplanation";
import CookiePolicy from "../pages/cookie-policy";
import Disclaimer from "../pages/disclaimer";

const HomePage = lazy(() => import("../pages"));
const TickerDetailsPage = lazy(() => import("../pages/tickers/[id]"));
const Profile = lazy(() => import("../pages/profile"));

const ProfileRoute = () => {
  // Get the dynamic username from the URL params
  const { username: paramsUsername } = useParams();

  // Get the logged-in username from the Redux state
  const username = useAppSelector((state) => state.auth.userData?.username);

  // If no user is logged in, redirect to home
  if (!username) {
    return (
      <GlobalLoader>
        <></>
      </GlobalLoader>
    );
  }

  if (!paramsUsername) return <Navigate to={`/profile/${username}`} replace />;

  return <Profile />;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: ROUTES.index,
        element: (
          <GlobalLoader>
            <HomePage />
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.tickers.index,
        element: (
          <GlobalLoader>
            <HomePage />
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.tickers.byId(),
        element: (
          <GlobalLoader>
            <PrivateRoute>
              <TickerDetailsPage />
            </PrivateRoute>
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.profile.index,
        element: (
          <GlobalLoader>
            <PrivateRoute>
              <ProfileRoute /> {/* Redirect to /profile/username */}
            </PrivateRoute>
          </GlobalLoader>
        ),
      },
      // Route for "/profile/:username" with dynamic username parameter
      {
        path: ROUTES.profile.byUsername(":username"),
        element: (
          <GlobalLoader>
            <PrivateRoute>
              <ProfileRoute /> {/* Validate and handle redirects */}
            </PrivateRoute>
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.terms_and_condition.index,
        element: (
          <GlobalLoader>
            <TermsAndConditions />
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.privacy_policy.index,
        element: (
          <GlobalLoader>
            <PrivacyPolicy />
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.cookie_policy.index,
        element: (
          <GlobalLoader>
            <CookiePolicy />
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.disclaimer.index,
        element: (
          <GlobalLoader>
            <Disclaimer />
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.score_explanation.index,
        element: (
          <GlobalLoader>
            <ScoreExplanation />
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.ticker_window_explanation.index,
        element: (
          <GlobalLoader>
            <TickerWindowExplanation />
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.wildCard,
        element: (
          <GlobalLoader>
            <PrivateRoute>
              <Navigate to="/" /> {/* Redirect to home or a custom 404 page */}
            </PrivateRoute>
          </GlobalLoader>
        ),
      },
    ],
  },
]);
