import { ChartLineTypeEnum, ChartTypeEnum, ITickerDetails, ITickerEditWindowLimits, ITickerIntervalsEnum, ITickerWindowLimits } from "../../../app/types/tickers";

const defaultConfig = {
    'collective': { color: '#7A04F1', label: "MemeStock", lineType: ChartLineTypeEnum.dashed, type: ChartTypeEnum.line }, //#F0803C
    'ticker': { color: '#4CAF50', label: "Ticker Performance", lineType: ChartLineTypeEnum.dashed, type: ChartTypeEnum.line }, //#E53935
    'userSubmission': { color: '#12C6FF', label: "User's Prediction Line", lineType: ChartLineTypeEnum.dashed, type: ChartTypeEnum.line, editable: true },
}

export const dummyTickerDetails: Record<ITickerIntervalsEnum, ITickerDetails> = {
    [ITickerIntervalsEnum.ONE_WEEK]: {
        "symbol": "GME",
        "symbolLabel": null,
        "currentTradingDay": "2024-11-20",
        "name": "GameStop",
        "sentiment": "really_bullish",
        "currentPrice": "34.56",
        "collectiveSentimentPrice": "57.32",
        "totalContributorCount": 100,
        "totalSubmissionCount": 561,
        "userSentimentPrice": "83.24",
        "todayCollectiveSentimentPrice": "22.11",
        "collectiveScore": 90,
        "displayData": [
            {
                "date": "2024-10-31",
                "collective": "100",
            },
            {
                "date": "2024-10-31",
                "ticker": "16",
                "userSubmission": "47"
            },
            {
                "date": "2024-11-01"
            },
            {
                "date": "2024-11-02"
            },
            {
                "date": "2024-11-03"
            },
            {
                "date": "2024-11-04",
                "collective": "18",
                "ticker": "27",
            },
            {
                "date": "2024-11-05"
            },
            {
                "date": "2024-11-06",
                "collective": "36",
                "userSubmission": "23.1"
            },
            {
                "date": "2024-11-07",
            },
            {
                "date": "2024-11-11",
            },
            {
                "date": "2024-11-12",
                "collective": "15",
            },
            {
                "date": "2024-11-13",
            },
            {
                "date": "2024-11-14",
            },
            {
                "date": "2024-11-15",
            },
            {
                "date": "2024-11-18",
            },
            {
                "date": "2024-11-20",
            }
        ],
        "chartConfig": {
            dateFormat: 'E, LLL d',
            ...defaultConfig
        }
    },
    [ITickerIntervalsEnum.ONE_MONTH]: {
        "symbol": "GME",
        "symbolLabel": null,
        "currentTradingDay": "2024-11-20",
        "name": "GameStop",
        "sentiment": "really_bullish",
        "currentPrice": "34.56",
        "collectiveSentimentPrice": "57.32",
        "totalContributorCount": 100,
        "totalSubmissionCount": 561,
        "userSentimentPrice": "83.24",
        "todayCollectiveSentimentPrice": "22.11",
        "collectiveScore": 90,
        "displayData": [
            {
                "date": "Tue Oct 01 2024"
            },
            {
                "date": "Tue Oct 15 2024",
                "collective": "146",
                "userSubmission": "115"
            },
            {
                "date": "Fri Nov 01 2024"
            },
            {
                "date": "Fri Nov 15 2024"
            },
            {
                "date": "Sun Dec 01 2024"
            },
            {
                "date": "Sun Dec 15 2024"
            }
        ],
        "chartConfig": {
            dateFormat: 'M/d',
            ...defaultConfig
        }
    },
    [ITickerIntervalsEnum.THREE_MONTHS]: {
        "symbol": "GME",
        "symbolLabel": null,
        "currentTradingDay": "2024-11-20",
        "name": "GameStop",
        "sentiment": "really_bullish",
        "currentPrice": "34.56",
        "collectiveSentimentPrice": "57.32",
        "totalContributorCount": 100,
        "totalSubmissionCount": 561,
        "userSentimentPrice": "83.24",
        "todayCollectiveSentimentPrice": "22.11",
        "collectiveScore": 90,
        "disableSubmitButton": false,
        "disableEditButton": true,
        "displayData": [
            {
                "date": "Thu Aug 01 2024",
                "ticker": "0",
                "userSubmission": "126"
            },
            {
                "date": "Thu Aug 15 2024",
                "collective": "230",
                "ticker": "121",
                "userSubmission": "117"
            },
            {
                "date": "Sun Sep 01 2024"
            },
            {
                "date": "Sun Sep 15 2024"
            },
            {
                "date": "Tue Oct 01 2024"
            },
            {
                "date": "Tue Oct 15 2024"
            },
            {
                "date": "Fri Nov 01 2024",
                "collective": "167",
                "ticker": "100",
            },
            {
                "date": "Fri Nov 15 2024"
            },
            {
                "date": "Sun Dec 01 2024"
            },
            {
                "date": "Sun Dec 15 2024",
                "collective": "112",
            },
            {
                "date": "Wed Jan 01 2025"
            },
            {
                "date": "Wed Jan 15 2025",
            },
            {
                "date": "Sat Feb 01 2025",
            },
            {
                "date": "Sat Feb 15 2025"
            }
        ],
        "chartConfig": {
            dateFormat: 'M/d',
            ...defaultConfig
        }
    },
    [ITickerIntervalsEnum.SIX_MONTHS]: {
        "symbol": "GME",
        "symbolLabel": null,
        "currentTradingDay": "2024-11-20",
        "name": "GameStop",
        "sentiment": "really_bullish",
        "currentPrice": "34.56",
        "collectiveSentimentPrice": "57.32",
        "totalContributorCount": 100,
        "totalSubmissionCount": 561,
        "userSentimentPrice": "83.24",
        "todayCollectiveSentimentPrice": "22.11",
        "collectiveScore": 90,
        "displayData": [
            {
                "date": "Wed May 01 2024"
            },
            {
                "date": "Sat Jun 01 2024",
                "collective": "54",
                "ticker": "95"
            },
            {
                "date": "Mon Jul 01 2024"
            },
            {
                "date": "Thu Aug 01 2024",
                "collective": "245",
                "ticker": "101",
                "userSubmission": "155"
            },
            {
                "date": "Sun Sep 01 2024",
                "userSubmission": "136"
            },
            {
                "date": "Tue Oct 01 2024"
            },
            {
                "date": "Fri Nov 01 2024",
                "collective": "186",
                "userSubmission": "53"
            },
            {
                "date": "Sun Dec 01 2024"
            },
            {
                "date": "Wed Jan 01 2025",
                "collective": "190",
                "ticker": "139"
            },
            {
                "date": "Sat Feb 01 2025",
                "userSubmission": "161"
            },
            {
                "date": "Sat Mar 01 2025"
            },
            {
                "date": "Tue Apr 01 2025",
                "collective": "162",
                "ticker": "199",
                "userSubmission": "148"
            },
            {
                "date": "Thu May 01 2025"
            }
        ],
        "chartConfig": {
            dateFormat: 'M/d',
            ...defaultConfig
        }
    },
    [ITickerIntervalsEnum.ONE_YEAR]: {
        "symbol": "GME",
        "symbolLabel": null,
        "currentTradingDay": "2024-11-20",
        "name": "GameStop",
        "sentiment": "really_bullish",
        "currentPrice": "34.56",
        "collectiveSentimentPrice": "57.32",
        "totalContributorCount": 100,
        "totalSubmissionCount": 561,
        "userSentimentPrice": "83.24",
        "todayCollectiveSentimentPrice": "22.11",
        "collectiveScore": 90,
        "displayData": [
            {
                "date": "Sun Oct 01 2023",
                "userSubmission": "244"
            },
            {
                "date": "Mon Jan 01 2024"
            },
            {
                "date": "Mon Apr 01 2024"
            },
            {
                "date": "Mon Jul 01 2024",
                "collective": "212"
            },
            {
                "date": "Tue Oct 01 2024",
                "ticker": "81"
            },
            {
                "date": "Wed Jan 01 2025"
            },
            {
                "date": "Tue Apr 01 2025",
                "collective": "169",
                "userSubmission": "77"
            },
            {
                "date": "Tue Jul 01 2025",
                "ticker": "131"
            },
            {
                "date": "Wed Oct 01 2025"
            }
        ],
        "chartConfig": {
            dateFormat: 'MMM Y',
            ...defaultConfig
        }
    }
}


export const dummyTickerWindowLimits: Record<ITickerIntervalsEnum, ITickerWindowLimits> = {
    [ITickerIntervalsEnum.ONE_WEEK]: {
        "strikeZone": [
            {
                "date": "2024-11-06",
                "highest": 23.1,
                "lowest": 23.1,
                "reallyBullishPrice": 23.1,
                "bullishPrice": 23.1,
                "neutralPrice": 23.1,
                "bearishPrice": 23.1,
                "reallyBearishPrice": 23.1
            },
            {
                "date": "2024-11-07",
                "highest": 72.98,
                "lowest": 0,
                "reallyBullishPrice": 72.98,
                "bullishPrice": 54.735,
                "neutralPrice": 36.49,
                "bearishPrice": 18.245000000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-11",
                "highest": 90.78,
                "lowest": 0,
                "reallyBullishPrice": 90.78,
                "bullishPrice": 68.08500000000001,
                "neutralPrice": 45.39,
                "bearishPrice": 22.694999999999993,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-12",
                "highest": 93.73,
                "lowest": 0,
                "reallyBullishPrice": 93.73,
                "bullishPrice": 70.2975,
                "neutralPrice": 46.865,
                "bearishPrice": 23.432500000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-13",
                "highest": 99.22,
                "lowest": 0,
                "reallyBullishPrice": 99.22,
                "bullishPrice": 74.41499999999999,
                "neutralPrice": 49.61,
                "bearishPrice": 24.805000000000007,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-14",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-15",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-18",
                "highest": 101.1,
                "lowest": 0,
                "reallyBullishPrice": 101.1,
                "bullishPrice": 75.82499999999999,
                "neutralPrice": 50.55,
                "bearishPrice": 25.275000000000006,
                "reallyBearishPrice": 0
            }
        ],
        "newPredictionLine": {
            "fromDate": "2024-11-06",
            "fromPrice": "23.1",
            "toDate": "2024-11-13",
            "toPrice": "46.55"
        }
    },
    [ITickerIntervalsEnum.ONE_MONTH]: {
        "strikeZone": [
            {
                "date": "2024-11-06",
                "highest": 23.1,
                "lowest": 23.1,
                "reallyBullishPrice": 23.1,
                "bullishPrice": 23.1,
                "neutralPrice": 23.1,
                "bearishPrice": 23.1,
                "reallyBearishPrice": 23.1
            },
            {
                "date": "2024-11-08",
                "highest": 72.98,
                "lowest": 0,
                "reallyBullishPrice": 72.98,
                "bullishPrice": 54.735,
                "neutralPrice": 36.49,
                "bearishPrice": 18.245000000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-11",
                "highest": 90.78,
                "lowest": 0,
                "reallyBullishPrice": 90.78,
                "bullishPrice": 68.08500000000001,
                "neutralPrice": 45.39,
                "bearishPrice": 22.694999999999993,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-12",
                "highest": 93.73,
                "lowest": 0,
                "reallyBullishPrice": 93.73,
                "bullishPrice": 70.2975,
                "neutralPrice": 46.865,
                "bearishPrice": 23.432500000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-13",
                "highest": 99.22,
                "lowest": 0,
                "reallyBullishPrice": 99.22,
                "bullishPrice": 74.41499999999999,
                "neutralPrice": 49.61,
                "bearishPrice": 24.805000000000007,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-14",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-15",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-18",
                "highest": 101.1,
                "lowest": 0,
                "reallyBullishPrice": 101.1,
                "bullishPrice": 75.82499999999999,
                "neutralPrice": 50.55,
                "bearishPrice": 25.275000000000006,
                "reallyBearishPrice": 0
            }
        ],
        "newPredictionLine": {
            "fromDate": "Tue Oct 15 2024",
            "fromPrice": "146",
            "toDate": "Sun Dec 15 2024",
            "toPrice": "200"
        }
    },
    [ITickerIntervalsEnum.THREE_MONTHS]: {
        "strikeZone": [
            {
                "date": "Fri Nov 01 2024",
                "highest": 100,
                "lowest": 100,
                "reallyBullishPrice": 100,
                "bullishPrice": 100,
                "neutralPrice": 100,
                "bearishPrice": 100,
                "reallyBearishPrice": 100
            },
            {
                "date": "Fri Nov 15 2024",
                "highest": 200,
                "lowest": 0,
                "reallyBullishPrice": 200,
                "bullishPrice": 150,
                "neutralPrice": 100,
                "bearishPrice": 50,
                "reallyBearishPrice": 0
            },
            {
                "date": "Sun Dec 01 2024",
                "highest": 250,
                "lowest": 0,
                "reallyBullishPrice": 250,
                "bullishPrice": 187.5,
                "neutralPrice": 125,
                "bearishPrice": 62.5,
                "reallyBearishPrice": 0
            },
            {
                "date": "Sun Dec 15 2024",
                "highest": 250,
                "lowest": 0,
                "reallyBullishPrice": 250,
                "bullishPrice": 187.5,
                "neutralPrice": 125,
                "bearishPrice": 62.5,
                "reallyBearishPrice": 0
            },
            {
                "date": "Wed Jan 01 2025",
                "highest": 250,
                "lowest": 0,
                "reallyBullishPrice": 250,
                "bullishPrice": 187.5,
                "neutralPrice": 125,
                "bearishPrice": 62.5,
                "reallyBearishPrice": 0
            },
            {
                "date": "Wed Jan 15 2025",
                "highest": 250,
                "lowest": 0,
                "reallyBullishPrice": 250,
                "bullishPrice": 187.5,
                "neutralPrice": 125,
                "bearishPrice": 62.5,
                "reallyBearishPrice": 0
            },
            {
                "date": "Sat Feb 01 2025",
                "highest": 250,
                "lowest": 0,
                "reallyBullishPrice": 250,
                "bullishPrice": 187.5,
                "neutralPrice": 125,
                "bearishPrice": 62.5,
                "reallyBearishPrice": 0
            },
            {
                "date": "Sat Feb 15 2025",
                "highest": 250,
                "lowest": 0,
                "reallyBullishPrice": 250,
                "bullishPrice": 187.5,
                "neutralPrice": 125,
                "bearishPrice": 62.5,
                "reallyBearishPrice": 0
            }
        ],
        "newPredictionLine": {
            "fromDate": "Fri Nov 01 2024",
            "fromPrice": "100",
            "toDate": "Sat Feb 01 2025",
            "toPrice": "240"
        }
    },
    [ITickerIntervalsEnum.SIX_MONTHS]: {
        "strikeZone": [
            {
                "date": "2024-11-06",
                "highest": 23.1,
                "lowest": 23.1,
                "reallyBullishPrice": 23.1,
                "bullishPrice": 23.1,
                "neutralPrice": 23.1,
                "bearishPrice": 23.1,
                "reallyBearishPrice": 23.1
            },
            {
                "date": "2024-11-08",
                "highest": 72.98,
                "lowest": 0,
                "reallyBullishPrice": 72.98,
                "bullishPrice": 54.735,
                "neutralPrice": 36.49,
                "bearishPrice": 18.245000000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-11",
                "highest": 90.78,
                "lowest": 0,
                "reallyBullishPrice": 90.78,
                "bullishPrice": 68.08500000000001,
                "neutralPrice": 45.39,
                "bearishPrice": 22.694999999999993,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-12",
                "highest": 93.73,
                "lowest": 0,
                "reallyBullishPrice": 93.73,
                "bullishPrice": 70.2975,
                "neutralPrice": 46.865,
                "bearishPrice": 23.432500000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-13",
                "highest": 99.22,
                "lowest": 0,
                "reallyBullishPrice": 99.22,
                "bullishPrice": 74.41499999999999,
                "neutralPrice": 49.61,
                "bearishPrice": 24.805000000000007,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-14",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-15",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-18",
                "highest": 101.1,
                "lowest": 0,
                "reallyBullishPrice": 101.1,
                "bullishPrice": 75.82499999999999,
                "neutralPrice": 50.55,
                "bearishPrice": 25.275000000000006,
                "reallyBearishPrice": 0
            }
        ],
        "newPredictionLine": {
            "fromDate": "Wed Jan 01 2025",
            "fromPrice": "139",
            "toDate": "Thu May 01 2025",
            "toPrice": "150"
        }
    },
    [ITickerIntervalsEnum.ONE_YEAR]: {
        "strikeZone": [
            {
                "date": "2024-11-06",
                "highest": 23.1,
                "lowest": 23.1,
                "reallyBullishPrice": 23.1,
                "bullishPrice": 23.1,
                "neutralPrice": 23.1,
                "bearishPrice": 23.1,
                "reallyBearishPrice": 23.1
            },
            {
                "date": "2024-11-08",
                "highest": 72.98,
                "lowest": 0,
                "reallyBullishPrice": 72.98,
                "bullishPrice": 54.735,
                "neutralPrice": 36.49,
                "bearishPrice": 18.245000000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-11",
                "highest": 90.78,
                "lowest": 0,
                "reallyBullishPrice": 90.78,
                "bullishPrice": 68.08500000000001,
                "neutralPrice": 45.39,
                "bearishPrice": 22.694999999999993,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-12",
                "highest": 93.73,
                "lowest": 0,
                "reallyBullishPrice": 93.73,
                "bullishPrice": 70.2975,
                "neutralPrice": 46.865,
                "bearishPrice": 23.432500000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-13",
                "highest": 99.22,
                "lowest": 0,
                "reallyBullishPrice": 99.22,
                "bullishPrice": 74.41499999999999,
                "neutralPrice": 49.61,
                "bearishPrice": 24.805000000000007,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-14",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-15",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-18",
                "highest": 101.1,
                "lowest": 0,
                "reallyBullishPrice": 101.1,
                "bullishPrice": 75.82499999999999,
                "neutralPrice": 50.55,
                "bearishPrice": 25.275000000000006,
                "reallyBearishPrice": 0
            }
        ],
        "newPredictionLine": {
            "fromDate": "Tue Apr 01 2025",
            "fromPrice": "169",
            "toDate": "Wed Oct 01 2025",
            "toPrice": "140"
        }
    }
}

export const dummyTickerEditWindowLimits: Record<ITickerIntervalsEnum, ITickerEditWindowLimits> = {
    [ITickerIntervalsEnum.ONE_WEEK]: {
        "strikeZone": [
            {
                "date": "2024-11-06",
                "highest": 23.1,
                "lowest": 23.1,
                "reallyBullishPrice": 23.1,
                "bullishPrice": 23.1,
                "neutralPrice": 23.1,
                "bearishPrice": 23.1,
                "reallyBearishPrice": 23.1
            },
            {
                "date": "2024-11-07",
                "highest": 72.98,
                "lowest": 0,
                "reallyBullishPrice": 72.98,
                "bullishPrice": 54.735,
                "neutralPrice": 36.49,
                "bearishPrice": 18.245000000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-11",
                "highest": 90.78,
                "lowest": 0,
                "reallyBullishPrice": 90.78,
                "bullishPrice": 68.08500000000001,
                "neutralPrice": 45.39,
                "bearishPrice": 22.694999999999993,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-12",
                "highest": 93.73,
                "lowest": 0,
                "reallyBullishPrice": 93.73,
                "bullishPrice": 70.2975,
                "neutralPrice": 46.865,
                "bearishPrice": 23.432500000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-13",
                "highest": 99.22,
                "lowest": 0,
                "reallyBullishPrice": 99.22,
                "bullishPrice": 74.41499999999999,
                "neutralPrice": 49.61,
                "bearishPrice": 24.805000000000007,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-14",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-15",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-18",
                "highest": 101.1,
                "lowest": 0,
                "reallyBullishPrice": 101.1,
                "bullishPrice": 75.82499999999999,
                "neutralPrice": 50.55,
                "bearishPrice": 25.275000000000006,
                "reallyBearishPrice": 0
            }
        ],
    },
    [ITickerIntervalsEnum.ONE_MONTH]: {
        "strikeZone": [
            {
                "date": "2024-11-06",
                "highest": 23.1,
                "lowest": 23.1,
                "reallyBullishPrice": 23.1,
                "bullishPrice": 23.1,
                "neutralPrice": 23.1,
                "bearishPrice": 23.1,
                "reallyBearishPrice": 23.1
            },
            {
                "date": "2024-11-08",
                "highest": 72.98,
                "lowest": 0,
                "reallyBullishPrice": 72.98,
                "bullishPrice": 54.735,
                "neutralPrice": 36.49,
                "bearishPrice": 18.245000000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-11",
                "highest": 90.78,
                "lowest": 0,
                "reallyBullishPrice": 90.78,
                "bullishPrice": 68.08500000000001,
                "neutralPrice": 45.39,
                "bearishPrice": 22.694999999999993,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-12",
                "highest": 93.73,
                "lowest": 0,
                "reallyBullishPrice": 93.73,
                "bullishPrice": 70.2975,
                "neutralPrice": 46.865,
                "bearishPrice": 23.432500000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-13",
                "highest": 99.22,
                "lowest": 0,
                "reallyBullishPrice": 99.22,
                "bullishPrice": 74.41499999999999,
                "neutralPrice": 49.61,
                "bearishPrice": 24.805000000000007,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-14",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-15",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-18",
                "highest": 101.1,
                "lowest": 0,
                "reallyBullishPrice": 101.1,
                "bullishPrice": 75.82499999999999,
                "neutralPrice": 50.55,
                "bearishPrice": 25.275000000000006,
                "reallyBearishPrice": 0
            }
        ],
    },
    [ITickerIntervalsEnum.THREE_MONTHS]: {
        "strikeZone": [
            {
                "date": "Fri Nov 01 2024",
                "highest": 100,
                "lowest": 100,
                "reallyBullishPrice": 100,
                "bullishPrice": 100,
                "neutralPrice": 100,
                "bearishPrice": 100,
                "reallyBearishPrice": 100
            },
            {
                "date": "Fri Nov 15 2024",
                "highest": 200,
                "lowest": 0,
                "reallyBullishPrice": 200,
                "bullishPrice": 150,
                "neutralPrice": 100,
                "bearishPrice": 50,
                "reallyBearishPrice": 0
            },
            {
                "date": "Sun Dec 01 2024",
                "highest": 250,
                "lowest": 0,
                "reallyBullishPrice": 250,
                "bullishPrice": 187.5,
                "neutralPrice": 125,
                "bearishPrice": 62.5,
                "reallyBearishPrice": 0
            },
            {
                "date": "Sun Dec 15 2024",
                "highest": 250,
                "lowest": 0,
                "reallyBullishPrice": 250,
                "bullishPrice": 187.5,
                "neutralPrice": 125,
                "bearishPrice": 62.5,
                "reallyBearishPrice": 0
            },
            {
                "date": "Wed Jan 01 2025",
                "highest": 250,
                "lowest": 0,
                "reallyBullishPrice": 250,
                "bullishPrice": 187.5,
                "neutralPrice": 125,
                "bearishPrice": 62.5,
                "reallyBearishPrice": 0
            },
            {
                "date": "Wed Jan 15 2025",
                "highest": 250,
                "lowest": 0,
                "reallyBullishPrice": 250,
                "bullishPrice": 187.5,
                "neutralPrice": 125,
                "bearishPrice": 62.5,
                "reallyBearishPrice": 0
            },
            {
                "date": "Sat Feb 01 2025",
                "highest": 250,
                "lowest": 0,
                "reallyBullishPrice": 250,
                "bullishPrice": 187.5,
                "neutralPrice": 125,
                "bearishPrice": 62.5,
                "reallyBearishPrice": 0
            },
            {
                "date": "Sat Feb 15 2025",
                "highest": 250,
                "lowest": 0,
                "reallyBullishPrice": 250,
                "bullishPrice": 187.5,
                "neutralPrice": 125,
                "bearishPrice": 62.5,
                "reallyBearishPrice": 0
            }
        ],
    },
    [ITickerIntervalsEnum.SIX_MONTHS]: {
        "strikeZone": [
            {
                "date": "2024-11-06",
                "highest": 23.1,
                "lowest": 23.1,
                "reallyBullishPrice": 23.1,
                "bullishPrice": 23.1,
                "neutralPrice": 23.1,
                "bearishPrice": 23.1,
                "reallyBearishPrice": 23.1
            },
            {
                "date": "2024-11-08",
                "highest": 72.98,
                "lowest": 0,
                "reallyBullishPrice": 72.98,
                "bullishPrice": 54.735,
                "neutralPrice": 36.49,
                "bearishPrice": 18.245000000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-11",
                "highest": 90.78,
                "lowest": 0,
                "reallyBullishPrice": 90.78,
                "bullishPrice": 68.08500000000001,
                "neutralPrice": 45.39,
                "bearishPrice": 22.694999999999993,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-12",
                "highest": 93.73,
                "lowest": 0,
                "reallyBullishPrice": 93.73,
                "bullishPrice": 70.2975,
                "neutralPrice": 46.865,
                "bearishPrice": 23.432500000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-13",
                "highest": 99.22,
                "lowest": 0,
                "reallyBullishPrice": 99.22,
                "bullishPrice": 74.41499999999999,
                "neutralPrice": 49.61,
                "bearishPrice": 24.805000000000007,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-14",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-15",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-18",
                "highest": 101.1,
                "lowest": 0,
                "reallyBullishPrice": 101.1,
                "bullishPrice": 75.82499999999999,
                "neutralPrice": 50.55,
                "bearishPrice": 25.275000000000006,
                "reallyBearishPrice": 0
            }
        ],
    },
    [ITickerIntervalsEnum.ONE_YEAR]: {
        "strikeZone": [
            {
                "date": "2024-11-06",
                "highest": 23.1,
                "lowest": 23.1,
                "reallyBullishPrice": 23.1,
                "bullishPrice": 23.1,
                "neutralPrice": 23.1,
                "bearishPrice": 23.1,
                "reallyBearishPrice": 23.1
            },
            {
                "date": "2024-11-08",
                "highest": 72.98,
                "lowest": 0,
                "reallyBullishPrice": 72.98,
                "bullishPrice": 54.735,
                "neutralPrice": 36.49,
                "bearishPrice": 18.245000000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-11",
                "highest": 90.78,
                "lowest": 0,
                "reallyBullishPrice": 90.78,
                "bullishPrice": 68.08500000000001,
                "neutralPrice": 45.39,
                "bearishPrice": 22.694999999999993,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-12",
                "highest": 93.73,
                "lowest": 0,
                "reallyBullishPrice": 93.73,
                "bullishPrice": 70.2975,
                "neutralPrice": 46.865,
                "bearishPrice": 23.432500000000005,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-13",
                "highest": 99.22,
                "lowest": 0,
                "reallyBullishPrice": 99.22,
                "bullishPrice": 74.41499999999999,
                "neutralPrice": 49.61,
                "bearishPrice": 24.805000000000007,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-14",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-15",
                "highest": 100.2,
                "lowest": 0,
                "reallyBullishPrice": 100.2,
                "bullishPrice": 75.15,
                "neutralPrice": 50.1,
                "bearishPrice": 25.049999999999997,
                "reallyBearishPrice": 0
            },
            {
                "date": "2024-11-18",
                "highest": 101.1,
                "lowest": 0,
                "reallyBullishPrice": 101.1,
                "bullishPrice": 75.82499999999999,
                "neutralPrice": 50.55,
                "bearishPrice": 25.275000000000006,
                "reallyBearishPrice": 0
            }
        ],
       
    }
}